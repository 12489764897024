<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">{{ workplace.id ? 'Edit' : 'New' }} workplace<br>
                <div class="mt-1">
                  <div class="form-check form-check-success form-switch d-flex align-items-center">
                    <input
                      id="publishContent"
                      type="checkbox"
                      class="form-check-input"
                    >
                    <label
                      class="form-check-label"
                      for="publishContent"
                    ><small class="ms-1">Publish <em
                      id="publishDate"
                      class="ms-2 text-muted"
                    ><small>12/12/2022</small></em></small></label>
                  </div>
                </div>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to workplaces list"
              @click="$router.push({ name: 'admin.workplaces.index' })"
            ><i data-feather="chevron-left" /></a>
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Senior call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Content</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Name</label>
                        <input
                          v-model="workplace.name"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Description</label>
                        <quill-editor
                          v-model="workplace.description"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >Host description (CAE)</label>
                        <quill-editor
                          v-model="workplace.host_description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <InternalNotes
              :notes="workplace.notes"
              @saveNewNote="saveNewNote"
            />

            <AddFile
              :id="9"
              :title="'Documentation'"
              :type="4"
              :visible="false"
              :files="workplace.files"
              @saveAnswer="saveAnswer"
            />

            <RelatedInfo
              v-if="!loading"
              :selected-data="workplace.hazards"
              :data="hazards"
              @saveRelatedInfo="saveRelatedInfo"
            />
          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" />
            <span class="ms-25 align-middle">Save and Exit</span>
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import InternalNotes from '../../partials/components/InternalNotes.vue'
import RelatedInfo from '../../partials/components/RelatedInfo.vue'
import AddFile from '../senior-call/components/AddFile.vue'

export default {
  components: {
    InternalNotes,
    AddFile,
    RelatedInfo,
  },
  data() {
    return {
      workplaceId: this.$route.params.id,
      sending: false,
      errors: '',
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      hazards: 'hazards/hazards',
      workplace: 'workplaces/workplace',
    }),
  },
  async mounted() {
    if (this.workplaceId) {
      await this.$store.dispatch('workplaces/fetchWorkplace', this.workplaceId)
    } else {
      await this.$store.dispatch('workplaces/cleanWorkplace')
    }
    this.loading = false

    if (!this.workplace.notes) {
      this.workplace.notes = []
    }

    if (this.hazards.length === 0) {
      await this.$store.dispatch('hazards/fetch')
    }
  },
  methods: {
    async save() {
      this.sending = true

      try {
        if (this.workplaceId) {
          await this.$store.dispatch('workplaces/update', { id: this.workplaceId, data: this.workplace })
        } else {
          await this.$store.dispatch('workplaces/create', this.workplace)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.workplace.id) {
        Vue.swal('The workplace has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'admin.workplaces.index' })
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
    saveNewNote(note) {
      this.workplace.notes.push(note)
    },
    saveRelatedInfo(data) {
      this.workplace.hazards = data
    },
    saveAnswer(data) {
      this.workplace.files_new = data.data
    },
  },

}
</script>
